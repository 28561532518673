import { entityIdMap } from "../../const/entity-id-map";
import { ExcludeMethod } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export const accountType = ['1', '2'] as const;
export type AccountType = typeof accountType[number];

function accountTypeValidator(val: unknown): val is AccountType {
	return typeof val == 'string' && (accountType as ReadonlyArray<string>).includes(val);
}

export class Client extends Entity {
	static typeId = entityIdMap['trade.client'];

	/** 顧客番号(半角数字15桁) */
	code!: string;
	/** メールアドレス */
	mail!: string;
	/** 顧客名 */
	name!: string;
	/** 顧客名(半角カナ) */
	nameKana?: string;
	/** 郵便番号 */
	zip?: string;
	/** 住所１ */
	address?: string;
	/** 住所２ */
	building?: string;
	/** 電話番号 */
	phone?: string;
	/** 金融機関コード */
	bankCode?: string;
	/** 支店コード */
	branchCode?: string;
	/** 口座種別(1:普通, 2:当座) */
	accountType?: '1' | '2';
	/** 口座番号 */
	accountNumber?: string;
	/** 口座名義人(半角カナ) */
	accountHolder?: string;
	/** 申込み元 */
	useServiceSquare?: boolean;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			code: { isMandatory: true, type: 'string' },
			mail: { isMandatory: true, type: 'string' },
			name: { isMandatory: true, type: 'string' },
			nameKana: { isMandatory: false, type: 'string' },
			zip: { isMandatory: false, type: 'string' },
			address: { isMandatory: false, type: 'string' },
			building: { isMandatory: false, type: 'string' },
			phone: { isMandatory: false, type: 'string' },
			bankCode: { isMandatory: false, type: 'string' },
			branchCode: { isMandatory: false, type: 'string' },
			accountType: { isMandatory: false, validator: accountTypeValidator },
			accountNumber: { isMandatory: false, type: 'string' },
			accountHolder: { isMandatory: false, type: 'string' },
			useServiceSquare: { isMandatory: false, type: 'boolean' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			code: this.code,
			mail: this.mail,
			name: this.name,
			nameKana: this.nameKana,
			zip: this.zip,
			address: this.address,
			building: this.building,
			phone: this.phone,
			bankCode: this.bankCode,
			branchCode: this.branchCode,
			accountType: this.accountType,
			accountNumber: this.accountNumber,
			accountHolder: this.accountHolder,
			useServiceSquare: this.useServiceSquare,
		});
	}

	hasValidAccountInfo(): boolean {
		return [
			this.bankCode,
			this.branchCode,
			this.accountType,
			this.accountNumber,
			this.accountHolder,
		].every(v => v);
	}
}
export type ClientAttr = Omit<ExcludeMethod<Client>, 'typeId'>;
