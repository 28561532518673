import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";
import { entityIdMap } from "../../const/entity-id-map";
import { User } from './User';

export class Session extends Entity {
	static typeId = entityIdMap['user.session'];

	user?: User;
	expire?: number;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			user: { isMandatory: false, model: User },
			expire: { isMandatory: false, type: 'number' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			user: this.user,
			expire: this.expire,
		});
	}
}