import { PageInfo } from '@sasagase/types';
import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import createParser from '../createParser';
import { CSVFileStruct, PageInfoStruct } from '../structs';
import { uri } from '../uri';
import { GetRewardRequestsRequestQuery } from './getRewardRequests';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string()
	}),
	query: s.object({
		csvFormat: s.optional(s.string()),
		isFormatGroup: s.optional(s.string()),
	}),
	body: s.object({
		requestIds: s.array(s.string()),
		pageInfo: s.optional(PageInfoStruct),
		requestedParams: s.optional(GetRewardRequestsRequestQuery),
		isRequestAfterPage: s.optional(s.boolean()),
	}),
};

export const PutRewardRequestTransactResponseStruct = s.type({
	csv: s.optional(s.array(CSVFileStruct)),
	sasagase: s.optional(s.object({
		orderIds: s.array(s.string()),
	})),
});
export type PutRewardRequestTransactResponse = s.Infer<typeof PutRewardRequestTransactResponseStruct>;
export const parsePutRewardRequestTransactRequestBody = createParser(ApiParamStructs.body);

export type PutRewardRequestTransactMethodParams = {
	shopId: string;
	requestIds: string[];
	pageInfo?: PageInfo;
	requestedParams?: s.Infer<typeof GetRewardRequestsRequestQuery>;
	isRequestAfterPage?: boolean;
	csvFormat?: string;
	isFormatGroup?: string;
};

export function putRewardRequestTransact(params: PutRewardRequestTransactMethodParams): APIRequest {
	const { shopId, requestIds, pageInfo, requestedParams, isRequestAfterPage, csvFormat, isFormatGroup } = params;
	return createAPIRequest(
		'put',
		uri`/api/y-review/${shopId}/request/transact`,
		{
			path: { shopId },
			query: { csvFormat, isFormatGroup },
			body: { requestIds, pageInfo, requestedParams, isRequestAfterPage },
		},
		ApiParamStructs
	);
}
